<template>
	<div id="rootVM" v-cloak>
		<div id="pageTitle">
			<b>주의작업이력 - 비디오</b>
		</div>
		<!-- Search -->
		<div class="panel panel-flat">
			<div class="panel-body">
				<form @submit.prevent="search" @keyup.enter.prevent="search">
					<div class="row">
						<!-- 달력 -->
						<div class="col-md-5">
							<div class="form-group">
								<label>{{ detailsFieldMap.searchWarnWorkOccurDtm }}</label>
								<input style="min-width: 210px" type="datetime-local" step="1" class="form-control" v-model="searchVM.warnWorkOccurDtmStat" />
								&nbsp;~&nbsp;
								<input
									style="min-width: 210px"
									type="datetime-local"
									step="1"
									class="form-control"
									v-model="searchVM.warnWorkOccurDtmEnd"
									:min="searchVM.warnWorkOccurDtmStat"
									:max="maxDay"
								/>
							</div>
						</div>
						<!-- 현장 -->
						<div class="col-md-3" style="width: 260px">
							<div class="form-group">
								<label>{{ detailsFieldMap.searchSite }}</label>
								<div class="select-wrapper">
									<select2 v-model="searchVM.siteId" :disabled="isDisabledSearchSite">
										<option value="">전체</option>
										<option v-for="item in options.siteOptions" :key="item.siteId" :value="item.siteId">
											{{ item.siteNm }}
										</option>
									</select2>
								</div>
							</div>
						</div>
						<!-- CCTV -->
						<div class="col-md-2" style="width: 250px; margin-left: 10px">
							<div class="form-group" v-if="cctvChoiceMethod == 'cctvSelect'">
								<label style="width: 73px">{{ detailsFieldMap.searchCctv }}</label>
								<div class="select-wrapper">
									<select2 v-model="searchVM.cctvInstallPlaceContSelect">
										<option value="">전체</option>
										<option v-for="item in options.cctvOptions" :key="item.cctvInstallPlaceCont" :value="item.cctvInstallPlaceCont">
											{{ item.cctvInstallPlaceCont }}
										</option>
									</select2>
								</div>
							</div>
							<div class="form-group" v-if="cctvChoiceMethod == 'cctvText'">
								<label style="width: 73px">{{ detailsFieldMap.searchCctv }}</label>
								<input type="text" class="form-control" :placeholder="detailsFieldMap.searchCctv" v-model="searchVM.cctvInstallPlaceContText" />
							</div>
						</div>
						<div class="col-md-1" style="width: 300px; margin-left: -30px; display: flex">
							<div class="form-group" style="width: 80px">
								<label style="margin-top: 8px">이력</label>
								<div class="checkbox" style="margin-bottom: 25px">
									<input type="checkbox" id="check" v-model="searchVM.isCheckedCctvAll" />
								</div>
							</div>
							<div style="margin-left: 20px">
								<span>
									(CCTV 검색어 입력 방법)
									<div style="margin-left: 25px">
										<input type="radio" v-model="cctvChoiceMethod" name="cctvSelect" value="cctvSelect" checked />
										<label for="cctvSelect">선택</label>
										<input type="radio" v-model="cctvChoiceMethod" name="cctvText" value="cctvText" />
										<label for="cctvText">텍스트</label>
									</div>
								</span>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-3" style="width: 310px; margin-left: 20px">
							<div class="form-group">
								<label>{{ detailsFieldMap.searchVideoWarnActnDvsnCd }}</label>
								<div class="select-wrapper">
									<select2 v-model="searchVM.videoWarnActnDvsnCd">
										<option value="">전체</option>
										<option v-for="item in options.videoWarnActnDvsnCdOption" :key="item.value" :value="item.value">
											{{ item.text }}
										</option>
									</select2>
								</div>
							</div>
						</div>
						<!-- 대상물 -->
						<div class="col-md-3" style="width: 300px">
							<div class="form-group">
								<label>{{ detailsFieldMap.searchObject }}</label>
								<div class="select-wrapper">
									<select2 v-model="searchVM.objectId">
										<option value="">전체</option>
										<option v-for="item in options.warnWorkOptions" :key="item.objectId" :value="item.objectId">
											{{ item.objectNm }}
										</option>
									</select2>
								</div>
							</div>
						</div>
						<!-- 조회 버튼 -->
						<div class="mb-10 text-right" style="float: right">
							<button type="submit" class="btn btn-labeled bg-primary">
								<b>
									<i class="icon-search4"></i>
								</b>
								조회
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
		<!-- Grid -->
		<div>
			<KendoGrid
				ref="grid"
				:auto-bind="false"
				:api-url="apiUrl.pageListApi"
				:columns="gridColumns"
				:apply-search-condition="applySearchStateOnGridLoad"
				@selected-row-item-changed="selectedRowItemChanged"
			></KendoGrid>
		</div>
		<div class="mt-10 mb-15 text-right"></div>
		<form id="detailsForm" class="form-horizontal form-validate-jquery" v-show="isEditMode">
			<div class="panel panel-flat">
				<div class="panel-heading">
					<h6 class="panel-title">
						<b>프레임 정보 ({{ this.detailsItem.cctvInstallPlaceCont }})</b>
					</h6>
					<div class="heading-elements">
						<ul class="icons-list">
							<li>
								<a @click="pannelHidden" data-action="collapse"></a>
							</li>
						</ul>
					</div>
				</div>
				<div class="panel-body">
					<div class="border" style="width: 57vw; height: 60vh; overflow-y: auto">
						<video-box
							v-if="isSelected == true"
							:key="keyForRerendering"
							:detailsItem="detailsItem"
							urlFlag="warn-work"
							:imgWrapper="detailsItem.inqWarnWorkOccurImgOutVOs"
						></video-box>
					</div>
					<div class="border" style="width: 14vw; margin: 0 0 20px 20px">
						<div class="rhtBox">
							<div><h3>대상물</h3></div>
							<div class="objectBox" v-for="(object, index) in detailsItem.objectNmsAllSequences" :key="index">
								<div style="font-size: 17px; margin-bottom: 10px; padding-left: 15px; margin-top: 15px; margin-left: 20px">
									{{ object }}
								</div>
								<div class="border-bottom" style="margin-top: 60px"></div>
							</div>
						</div>
						<div class="border-bottom"></div>
						<div class="rhtBox" style="border: none">
							<div><h3>작업 이력</h3></div>
							<div style="margin-top: 30px; margin-left: 20px; padding-left: 15px">
								- {{ detailsItem.warnWorkOccurDtm }} / {{ detailsItem.videoWarnActnDvsnCdNm }}
							</div>
						</div>
						<div class="border-bottom" style="margin-bottom: 10px"></div>
						<div style="text-align: right; margin-bottom: 10px; margin-right: 20px" v-if="detailsItem.videoWarnActnDvsnCd != '04'">
							<button
								class="btn bg-primary"
								style="flex: 1; margin: 0px 5px"
								@click.stop="misdetection"
								v-if="detailsItem.videoWarnActnDvsnCd != '05'"
							>
								오인식
							</button>
							<button
								class="btn bg-primary"
								style="flex: 1; margin: 0px 5px"
								@click.stop="deMisdetection"
								v-if="detailsItem.videoWarnActnDvsnCd == '05'"
							>
								오인식 취소
							</button>
						</div>
					</div>
				</div>
			</div>
		</form>

		<!-- <div ref="showPlayVideo" class="modal">
			<div class="modal-content">
				<span @click="showPlayVideoClose" class="close">&times;</span>
				<div style="height: 30px"></div>
				<div class="row" style="display: flex; align-items: center; justify-content: center">
					<video width="100%" autoplay controls :src="this.playVideoUrl"></video>
				</div>
			</div>
		</div> -->
	</div>
</template>

<script>
import VideoBox from '@/components/VideoBox';
import KendoGrid from '@/components/KendoGrid';
import Select2 from '@/components/Select2';
import extend from 'extend';
import moment from 'moment';
import _ from 'lodash';
import apiIndex from '../../api/index';
// const baseUrl = apiIndex.baseUrl;
const warnWorkOccurUrl = apiIndex.warnWorkOccur;

let axiosExtention;

export default {
	components: {
		KendoGrid,
		select2: Select2,
		VideoBox,
	},
	data: () => ({
		pageParam: null,
		apiUrl: {
			pageListApi: warnWorkOccurUrl.inqWarnWorkOccur,
		},
		searchVM: {},
		// 상세 select options
		detailsOptions: {},
		options: {},
		// 적용된 검색조건
		searchState: {},
		gridColumns: [
			{ field: 'warnWorkOccurDtm', title: '발생시각', align: 'center' },
			{ field: 'siteNm', title: '현장명' },
			{ field: 'cctvInstallPlaceCont', title: 'CCTV' },
			{ field: 'thumbNailImgByte', title: '이미지', width: '10%', thumbNail: true },
			{ field: 'objectNm', title: '대상물명' },
			{
				field: 'videoWarnActnDvsnCd',
				title: '주의 조치 코드',
				dataFormat: 'optionMap',
				align: 'center',
			},
		],
		detailsFieldMap: {
			searchSite: '현장명',
			searchWarnWorkOccurDtm: '발생시각',
			searchCctv: 'CCTV',
			searchObject: '대상물명',
			searchVideoWarnActnDvsnCd: '주의 조치 코드',
		},
		//상세편집 원본
		detailsItemOriginal: {},
		//상세편집 수정본
		detailsItem: {},
		popupImg: {},
		isDisabledSearchSite: true,
		// activePicture: 0,
		// isImagePoppedUp: false,
		warnWorkOccurSequenceMap: {},
		// playVideoUrl: '',
		//warnWorkOccurDtmEnd의 지정 가능한 최대 날짜
		maxDay: '',
		isSelected: false,
		keyForRerendering: 0,
		cctvChoiceMethod: 'cctvSelect',
	}),
	created() {
		this.pageParam = JSON.parse(sessionStorage.getItem('warnWorkOccurVideoPageParam'));
		if (!this.pageParam) location.href = apiIndex.mainUrl;
		this.searchVM = {
			warnWorkOccurDtmStat: moment().format('YYYY-MM-DD 00:00:00'),
			warnWorkOccurDtmEnd: moment().add(1, 'days').format('YYYY-MM-DD 00:00:00'),
			siteId: this.pageParam.loginUserSiteId,
			cctvInstallPlaceContSelect: '',
			cctvInstallPlaceContText: '',
			warnWorkId: '',
			objectId: '',
			videoWarnActnDvsnCd: '01',
			isCheckedCctvAll: false,
		};

		const commonCodeList = JSON.parse(sessionStorage.getItem('commonCodeList'));

		this.options = {
			siteOptions: this.pageParam.siteOptions,
			cctvOptions: this.pageParam.cctvOptions,
			warnWorkOptions: this.pageParam.warnWorkOptions,
			videoWarnActnDvsnCdOption: commonCodeList.filter(item => item.grpCd == 'video_warn_actn_dvsn_cd'),
		};

		this.gridColumns.filter(item => item.field == 'videoWarnActnDvsnCd')[0].mapData = this.options.videoWarnActnDvsnCdOption;

		axiosExtention = this.$jarvisExtention.axiosExtention;

		this.isDisabledSearchSite = this.pageParam.loginUserRolecd === '00' ? false : true;
	},
	mounted() {
		this.search();
	},
	computed: {
		isEditMode() {
			return !_.isEmpty(this.detailsItem) && !_.isEmpty(this.detailsItemOriginal);
		},
		isCreateMode() {
			return !_.isEmpty(this.detailsItem) && _.isEmpty(this.detailsItemOriginal);
		},
		validationRule() {
			return {
				detailsItem: this.isEditMode ? this.detailsValidationRule.edit : this.detailsValidationRule.create,
			};
		},
		isDisabled() {
			return false;
		},
	},
	watch: {
		detailsItem(newVal) {
			this.$validator.reset(); //Validation상태 초기화
			//details활성화 시 스크롤 이동처리
			if (!_.isEmpty(newVal)) {
				this.$nextTick(function () {
					window.scrollTo({ top: this.getDetailsFormPositionForScrolling(), behavior: 'smooth' });
				});
			}
		},
		'searchVM.siteId': {
			handler(value, oldValue) {
				if (oldValue !== undefined) {
					this.$axios
						.post(warnWorkOccurUrl.inqWarnWorkOccurCctvInfo, { siteId: this.searchVM.siteId, isCheckedCctvAll: this.searchVM.isCheckedCctvAll })
						.then(
							function (r) {
								this.options.cctvOptions = r.data;
								this.searchVM.cctvInstallPlaceContSelect = '';
							}.bind(this),
						)
						.catch(axiosExtention.buildErrorHandler());
				}
			},
		},
		'searchVM.isCheckedCctvAll': {
			handler() {
				this.$axios
					.post(warnWorkOccurUrl.inqWarnWorkOccurCctvInfo, { siteId: this.searchVM.siteId, isCheckedCctvAll: this.searchVM.isCheckedCctvAll })
					.then(
						function (r) {
							this.options.cctvOptions = r.data;
						}.bind(this),
					)
					.catch(axiosExtention.buildErrorHandler());
			},
		},
		//조회기간을 최대 x개월 간으로 하기 위해
		'searchVM.warnWorkOccurDtmStat': {
			handler(value) {
				this.maxDay = moment(value).add(process.env.VUE_APP_MAX_MONTH, 'month').format('YYYY-MM-DD');
			},
		},
		cctvChoiceMethod: {
			handler(value, oldValue) {
				if (value != oldValue) {
					this.searchVM.cctvInstallPlaceContSelect = '';
					this.searchVM.cctvInstallPlaceContText = '';
				}
			},
		},
		// // 팝업 모달이 생겨났을 때, 해당 이미지에 포커스를 두도록(키보드로 이미지를 바로 이동시킬 수 있게 하기 위함)
		// isImagePoppedUp() {
		// 	if (this.isImagePoppedUp == true) {
		// 		this.$nextTick(function () {
		// 			let selectedImage = document.getElementsByClassName('active')[0];
		// 			selectedImage.focus();
		// 		});
		// 	}
		// },
		// // 활성화된 이미지가 변경됐을 때 이미지 데이터를 변경하기 위함
		// activePicture() {
		// 	this.popupImg = this.detailsItem.inqWarnWorkOccurImgOutVOs[this.activePicture];
		// },
	},
	methods: {
		getDetailsFormPositionForScrolling() {
			let element = document.getElementById('detailsForm');
			return element.getBoundingClientRect().top + window.scrollY;
		},
		// warnWorkOccurSequenceMap 맵에 시퀀스를 키로, 대상물을 세팅
		setObjectsToSequenceMap() {
			this.detailsItem.inqWarnWorkOccurImgOutVOs.forEach(imgInfo => {
				this.detailsItem.inqWarnWorkFrameImgDetailVOs.forEach(e => {
					if (e.frameImgSeq == imgInfo.frameImgSeq && this.warnWorkOccurSequenceMap[imgInfo.frameImgSeq] == undefined) {
						this.$set(this.warnWorkOccurSequenceMap, imgInfo.frameImgSeq, new Array(e.objectNm));
					} else if (e.frameImgSeq == imgInfo.frameImgSeq) {
						this.setObjectNmstWithoutDuplication(e, this.warnWorkOccurSequenceMap);
					}
				});
			});
		},
		// dangerOccurObjectsAtSequenceMap 을 활용해서 대상물과 조치사항이 있는 시퀀스에 대상물과 조치사항을 세팅
		setObjectsToDetailsItem() {
			this.detailsItem.inqWarnWorkOccurImgOutVOs.forEach(e => {
				this.$set(e, 'objects', this.warnWorkOccurSequenceMap[e.frameImgSeq]);
			});
		},
		setObjectNmstWithoutDuplication(vo, sequenceMap) {
			if (sequenceMap[vo.frameImgSeq] == undefined) {
				return;
			}
			let filter = sequenceMap[vo.frameImgSeq].filter(e => e == vo.objectNm);
			if (filter.length == 0) {
				sequenceMap[vo.frameImgSeq].push(vo.objectNm);
			}
		},
		//검색적용
		search() {
			this.searchState = extend(true /*deep*/, {}, this.searchVM);
			this.loadGrid();
		},
		//그리드로드
		loadGrid() {
			this.closeDetails();
			this.$refs.grid.load();
		},
		//그리드로드시 검색조건적용
		applySearchStateOnGridLoad(param) {
			return extend(true /*deep*/, param, this.searchState || {});
		},
		//그리드선택처리
		selectedRowItemChanged(selectedRowItem) {
			this.detailsItem = {};
			this.warnWorkOccurSequenceMap = {};
			if (!_.isEmpty(selectedRowItem)) {
				this.$axios
					.post(warnWorkOccurUrl.inqOneWarnWorkOccurVideo, {
						siteId: selectedRowItem.siteId,
						cctvId: selectedRowItem.cctvId,
						warnWorkOccurSeq: selectedRowItem.warnWorkOccurSeq,
						strtFrameImgSeq: selectedRowItem.strtFrameImgSeq,
						endFrameImgSeq: selectedRowItem.endFrameImgSeq,
					})
					.then(
						function (response) {
							this.detailsItemOriginal = extend(true, {}, response.data);
							this.detailsItem = extend(true, {}, response.data);

							this.setAllFramesToImageData();

							// 대상물 분리
							// if (this.detailsItem.objectNms) {
							// 	this.detailsItem.objectNms = this.detailsItem.objectNms.split(',');
							// }
							// 구분코드 명칭 가져오기
							for (let index = 0; index < this.options.videoWarnActnDvsnCdOption.length; index++) {
								if (this.options.videoWarnActnDvsnCdOption[index].value === this.detailsItem.videoWarnActnDvsnCd) {
									this.detailsItem.videoWarnActnDvsnCdNm = this.options.videoWarnActnDvsnCdOption[index].text;
									break;
								} else {
									this.detailsItem.videoWarnActnDvsnCdNm = '';
								}
							}
							// // warnWorkOccurSequenceMap 맵에 시퀀스를 키로, 대상물을 세팅
							// this.setObjectsToSequenceMap();
							// // warnWorkOccurSequenceMap 을 활용해서 대상물과 조치사항이 있는 시퀀스에 대상물을 세팅
							// this.setObjectsToDetailsItem();
							this.isSelected = true;
							this.keyForRerendering += 1;
						}.bind(this),
					)
					.catch(axiosExtention.buildErrorHandler());
			} else {
				//상세화면 닫기 (다시로드등으로 선택변경)
				this.closeDetails();
			}
		},
		//이미지 데이터 세팅
		setAllFramesToImageData() {
			this.detailsItem.inqWarnWorkOccurImgOutVOs.forEach(e => {
				this.$axios.post(warnWorkOccurUrl.imgOneWarnWorkOccur, { fileName: e.imgSaveFileNm }).then(response => {
					e.imgData = 'data:image/jpg;base64,' + response.data.imgByte;
				});
			});
		},
		//상세화면 닫기
		closeDetails() {
			this.isSelected = false;
			this.detailsItemOriginal = {};
			this.detailsItem = {};
		},
		// 오인식
		misdetection() {
			if (this.detailsItem.videoWarnActnDvsnCd === '05') {
				alert('이미 오인식 처리되었습니다.');
			} else {
				let tempItem = this.detailsItem;
				tempItem.objectNms = '';
				this.$axios
					.post(warnWorkOccurUrl.updWarnWorkOccurMisdetection, tempItem)
					.then(
						function () {
							this.loadGrid();
						}.bind(this),
					)
					.catch(axiosExtention.buildErrorHandler(this.detailsFieldMap));
			}
		},
		// 오인식 취소
		deMisdetection() {
			if (this.detailsItem.videoWarnActnDvsnCd !== '05') {
				alert('오인식 상태가 아닙니다.');
			} else {
				this.$axios
					.post(warnWorkOccurUrl.updWarnWorkOccurDeMisdetection, this.detailsItem)
					.then(
						function () {
							this.loadGrid();
						}.bind(this),
					)
					.catch(axiosExtention.buildErrorHandler());
			}
		},
		// sendMakeWarnWorkVideo() {
		// 	console.log(this.detailsItem);
		// 	this.$axios
		// 		.post(warnWorkOccurUrl.sendMakeWarnWorkVideo, this.detailsItem)
		// 		.then(function () {}.bind(this))
		// 		.catch(axiosExtention.buildErrorHandler());
		// },
		// warnWorkOccurImgClicked(item) {
		// 	this.$axios
		// 		.post(warnWorkOccurUrl.sendMakeWarnWorkVideo, {
		// 			siteId: item.siteId,
		// 			cctvId: item.cctvId,
		// 			strtFrameImgSeq: item.frameImgSeq,
		// 			imgSaveDtm: item.imgSaveDtm,
		// 			imgSaveFileNm: item.imgSaveFileNm,
		// 		})
		// 		.then(
		// 			function (r) {
		// 				this.playVideoUrl = baseUrl + '/v2/api/video/danger/' + r.data;
		// 				this.$refs.showPlayVideo.style.display = 'block';
		// 			}.bind(this),
		// 		)
		// 		.catch(axiosExtention.buildErrorHandler());
		// },
		pannelHidden(e) {
			// click event 에서 target pannel 가져오기
			let pannel = $(e.target).closest('.panel').find('.panel-body')[0];
			if (pannel) {
				if (pannel.style.display === 'none') {
					e.target.classList.remove('rotate-180');
					pannel.style.display = 'block';
				} else {
					e.target.classList.add('rotate-180');
					pannel.style.display = 'none';
				}
			}
		},
		// showPlayVideoClose() {
		// 	// 영상보기 닫기
		// 	this.$refs.showPlayVideo.style.display = 'none';
		// },
	},
};
</script>

<style>
.select-wrapper > span {
	width: 190px !important;
}
.objectBox {
	/* height: 135px; */
	min-height: 100px;
	height: auto;
}

*:focus {
	outline: none;
}
.modal-content img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.modal-content [photo] {
	cursor: pointer;
	border-radius: 4px;
	overflow: hidden;
}
.modal-content [photo] img {
	min-height: 120px;
	height: 120px;
}
.modal-content [photo].active {
	border: 5px solid #1878f3;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 5px;
}
.modal-content [left] {
	padding: 0 15px;
}
.modal-content [left] [photo] {
	margin-top: 20px;
}

[v-cloak] > * {
	display: none !important;
}

#rootVM > .panel {
	display: flex;
	padding: 40px 20px;
}

#rootVM > .panel .panel-body {
	margin: auto;
}

#rootVM > .panel .panel-body form .row .form-group {
	display: flex;
	align-items: center;
	margin: 0;
}

#rootVM > .panel .panel-body .row:first-child {
	display: flex;
	align-items: center;
	margin-bottom: 20px;
}

#rootVM > .panel .panel-body .row:last-child .mb-10 {
	margin: 0 !important;
}

#rootVM > .panel .panel-body .form-group label {
	width: 70%;
	margin-top: 5px;
	max-width: 130px;
}

/* The Modal (background) */
.modal {
	display: none; /* Hidden by default */
	position: fixed; /* Stay in place */
	z-index: 99; /* Sit on top */
	left: 0;
	top: 0;
	width: 100%; /* Full width */
	height: 100%; /* Full height */
	background-color: rgb(0, 0, 0); /* Fallback color */
	background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
	/* background-color: #fefefe; */
	background-color: #e0e0e0;
	margin: 4% auto; /* 4% from the top and centered */
	padding: 20px;
	border: 1px solid #888;
	/* width: 1642px; */
	/* width: 60%; */ /* Could be more or less, depending on screen size */
	width: 1300px;
	height: 800px;
}
/* The Close Button */
.close {
	color: #aaa;
	float: right;
	font-size: 28px;
	font-weight: bold;
}
.close:hover,
.close:focus {
	color: black;
	text-decoration: none;
	cursor: pointer;
}
label {
	text-align: right;
	margin-right: 12px;
}
.panel-heading {
	padding-left: 20px !important;
}
.border {
	border-radius: 3px;
	border: 1px solid #ddd;
	float: left;
}
.border-bottom {
	width: 90%;
	margin: auto;
	border-bottom: 1px solid #ddd !important;
}
.rhtBox {
	min-height: 135px;
	height: auto;
}
.rhtBox > div:first-child {
	/* background: #f9f9f9; */
	background: #e0e0e0;
}
h3 {
	font-weight: bold !important;
	margin: 0 !important;
	padding: 10px 10px 10px 15px;
}
.mBox {
	/* height: 135px; */
	height: 170px;
	border-radius: 3px;
	border: 1px solid #ddd;
	/* margin-bottom: 50px; */
	margin-bottom: 30px;
	margin-top: 30px;
}
.mBox > div:first-child {
	/* background: #f9f9f9; */
	background: #e0e0e0;
}
.modal-dialog > .modal-content {
	width: 400px;
	padding: 0;
	height: 182px;
}
</style>
